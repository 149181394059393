import 'ion-rangeslider'

$(function() {
  $('[data-js-range-slider]').ionRangeSlider({
    onFinish: function (data) {
      $('[data-js-range-min]').val(data.from);
      $('[data-js-range-max]').val(data.to);

      // OVERRIDE
      // In core we prevent slider from submitting on mobile
      // But we allow here because of layout differences
      // if (!mediumAndUp()) { return; }
      // END OF OVERRIDE
      $('[data-filters]').submit();
    },
  });
});
