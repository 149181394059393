(function(){
  function setupModal() {
    // DOM nodes
    const modalOpenTriggers = Array.from(document.querySelectorAll('[data-open-modal]'));
    const modalCloseTriggers = Array.from(document.querySelectorAll('[data-close-modal]'));
    const modals = Array.from(document.querySelectorAll('[data-modal]'));

    // Methods
    function openModal(id) {
      const modal = document.querySelector(`[data-modal="${id}"]`);

      modal.removeAttribute('hidden');
    }

    function closeModal(id) {
      const modal = document.querySelector(`[data-modal="${id}"]`);

      modal.setAttribute('hidden','hidden');
    }

    // Attach event handlers
    modalOpenTriggers.forEach(function(trigger) {
      trigger.addEventListener('click', function(event) {
        const id = event.target.getAttribute('data-open-modal');

        openModal(id)
      }, false)
    });

    modalCloseTriggers.forEach(function(trigger) {
      trigger.addEventListener('click', function(event) {
        const id = event.target.getAttribute('data-close-modal');

        closeModal(id)
      }, false)
    });

    modals.forEach(function(modal) {
      modal.addEventListener('click', function(event) {
        const clicked = event.target;
  
        if (clicked.hasAttribute('data-modal')) {
          const id = clicked.getAttribute('data-modal');

          closeModal(id);
        }
      }, true)
    });
  }

  // Init when DOM is ready
  document.addEventListener('DOMContentLoaded', setupModal, false);
}());
