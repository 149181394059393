import 'store_connect/packs/store_connect';

import 'ion.rangeSlider.min';
// import 'hover-intent.min';
import hoverintent from 'hoverintent/dist/hoverintent.min';
window.hoverintent = hoverintent;
import 'hammer.min';
import 'tiny-slider';
import 'select2.min';
import 'pickaday';

import 'store_connect/theme/bundle';
import 'store_connect/theme/live-cart-update';
import '../theme/sort';
import '../theme/tabs';
import '../theme/price-slider';

import * as Viewport from 'store_connect/theme/viewport';
window.StoreConnectUI.Viewport = Viewport;
import 'store_connect/theme/date';
import 'store_connect/theme/accordion';
import 'store_connect/theme/cart';
import 'store_connect/theme/loader';
import 'store_connect/theme/menu';
import 'store_connect/theme/dropdown';
import 'store_connect/theme/nav';
import 'store_connect/theme/product-comparison';
import 'store_connect/theme/product-images';
import 'store_connect/theme/product-gallery';
import 'store_connect/theme/quantity-picker';
import 'store_connect/theme/slider';
import 'store_connect/theme/tabs';
import 'store_connect/theme/toggle-active';
import 'store_connect/theme/toggle-visibility';
import 'store_connect/theme/toggle-required';
import 'store_connect/theme/nav';
import 'store_connect/theme/fulfilment';
import 'store_connect/theme/product-variants';

import '../custom/modal';
import '../custom/option-select-show';
import '../custom/search-toggle';
import '../custom/tabs-vertical';
