// TODO: Refactor so address forms (shipping and billing forms) update independently based on data from server.
// As of now updating Required Fields based on selected Country doesn't
// work well because a change in one country field (either shipping_country or billing_country) updates both forms.
// To temporarily remedy this, we've hardcoded `updateRequired` method calls to only use value of shipping country
// hence updating billing country won't do anything to the form.
import Rails from '@rails/ujs';
import 'select2'

window.StoreConnect = window.StoreConnect || {};

window.StoreConnect.address = function(options) {
  var state_id = options['state_id'];
  var country_id = options['country_id'];
  var country_select = $('#' + country_id);
  const billing_same_as_shipping_element = document.getElementById(options['billing_same_id']);

  var state_select = $('#' + state_id);
  var state_name = state_select.attr('name');
  var state_class = state_select.attr('class');
  if (state_class === undefined) {
    state_class = ''
  }

  statesDropdown();
  var billing_same_as_shipping = false;
  if (billing_same_as_shipping_element) {
    billing_same_as_shipping = billing_same_as_shipping_element.checked;
    billing_same_as_shipping_element.addEventListener("change", event => {
      updateRequired(country_select.val(), event.target.checked);
    });
  }

  // Temporarily limiting update of required fields based on chosen shipping country, see note at the top
  var shipping_country_id = country_id && country_id.includes('shipping_country') ? country_id : null;
  var shipping_country_select = shipping_country_id ? $('#' + shipping_country_id) : null;
  var shipping_country_select_value = shipping_country_select ? shipping_country_select.val() : '';

  if (shipping_country_select) updateRequired(shipping_country_select_value, billing_same_as_shipping);

  // ====== ***** METHODS ***** ===================================================================== //
  function updateRequiredFields(data){
    [...document.querySelectorAll('[data-required-fields-form] input, [data-required-fields-form] select')].map(input => {
      const name = input.name.match(/\[(.*)\]/);
      if (name) {
        const label = input.labels[0];
        if (data.includes(name[1])) {
          input.required = true;
          input.dataset.required = true;
          label.dataset.required = true;
        } else {
          input.required = false;
          delete input.dataset.required;
          delete label.dataset.required;
        }
      }
    });
  }

  function updateRequired(country_id, billing_same_as_shipping) {
    Rails.ajax({
      url: "/checkout/required_fields",
      type: 'POST',
      data: new URLSearchParams({ country_id: country_id, billing_same_as_shipping: billing_same_as_shipping }).toString(),
      success: function (data) { updateRequiredFields(data) }
    });
  }

  function statesDropdown() {
    addChosenToCountry();
    addChosenToState();

    findStates(country_select.val());

    country_select.change(function () {
      // Temporarily limiting update of required fields based on chosen shipping country, see note at the top
      if (shipping_country_select) updateRequired(shipping_country_select.val());
      return findStates($(this).val());
    });

  }

  function addChosenToState(){
    if (chosenIsRequired() && stateIsNotText()) {
      $('#' + state_id).chosen(options['chosen_options']);
    }
  }

  function stateIsNotText(){
    return !$('#' + state_id).is("[type=text]");
  }


  function addChosenToCountry(){
    if (chosenIsRequired()) {
      country_select.chosen(options['chosen_options']);
    }
  }

  function removeChosenFromFields(){
    if (chosenIsRequired()) {
      $("#" + options['state_id'] + "_chosen").remove();
    }
  }

  function chosenIsRequired(){
    return options.hasOwnProperty("chosen_ui") && options['chosen_ui'];
  }

  function findStates(id) {
    if (id === "") { return(false) };
    //Remove all Chosen from existing fields
    removeChosenFromFields();

    //Perform AJAX request to get the data; on success, build the dropdown
    $.ajax({
      url: "/checkout/find_states",
      type: 'post',
      dataType: 'json',
      cache: false,
      data: {country_id: id},
      success: function (data) { buildStatesDropdown(data) }
    });
  }

  //Build the HTML for our dropdown menus
  function buildStatesDropdown(data) {
    let html;

    if (data.length === 0) {
      html = '<input id="' + state_id + '" name="' + state_name + '" class="' + state_class + '" type="text"  type="text" value="" >';
    } else {
      html = '<select id="' + state_id + '" name="' + state_name + '" class="' + state_class + '" >';
      html += '<option value="">'+ find_select_option_text('state') +'</option>'

      for (let i = 0; i < data.length; i++) {
        html += '<option value=' + data[i][0]
        if(state_select.data('selected') === data[i][0]) {
          html += ' selected'
        }
        html += '>' + data[i][1] + '</option>';
      }

      html += '</select>';
    }

    $('#' + state_id).replaceWith(html);

    //This has to happen AFTER we've replaced the dropdown or text
    if (data.length > 0) {
      addChosenToState();
    }
  }

  function find_select_option_text(type){
    return options['state_place_holder']
  }
}


window.StoreConnect.address_autocomplete = function(options) {
  $('#checkout_customer_information_form_address_autocomplete').select2({
    placeholder: 'Find Address',
    width: 'style',
    ajax: {
      url: '/checkout/valid_addresses',
      dataType: 'json'
    },
  });

  $('#checkout_customer_information_form_address_autocomplete').on('select2:select', function (e) {
    const address = e.params.data;
    $('#checkout_customer_information_form_shipping_address_1').val(address['address_1']);
    $('#checkout_customer_information_form_shipping_city').val(address['city']);
    $('#checkout_customer_information_form_shipping_state').val(address['state']);
    $('#checkout_customer_information_form_shipping_postal_code').val(address['postal_code']);
  });

  if (options['supported_countries'].length > 0) {
    $('#checkout_customer_information_form_shipping_country').change(function () {
      if ($(this).val() == 'AU') {
        $('#address_autocomplete_container').show();
      } else {
        $('#address_autocomplete_container').hide();
      }
    });

    $('#checkout_customer_information_form_shipping_country').val(options['supported_countries'][0]);
  }
}
