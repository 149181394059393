window.StoreConnectUI = window.StoreConnectUI || {};

window.StoreConnectUI.Tabs = function() {
  let sets = {};

  document.addEventListener('DOMContentLoaded', function() {
    const tabSets = document.querySelectorAll('[data-tabs]');

    if (tabSets.length > 0) {
      [...tabSets].map(set => init(set));
    }
  });

  function init(set) {
    const setId = set.getAttribute('data-tabs');
    const tabsInSet = [...set.querySelectorAll('[data-tab]')];
    const triggersInSet = [...set.querySelectorAll('[data-tab-trigger]')];
    const activeTabInSet = getActiveTabElem(tabsInSet);

    sets[setId] = {
      tabs: tabsInSet,
      triggers: triggersInSet,
      active: activeTabInSet
    }

    // Activate the first tab if none are already active
    if (activeTabInSet === undefined) {
      if (tabsInSet[0] === undefined) {
        return;
      }
      const firstTabId = getId(tabsInSet[0]);

      activate(firstTabId, setId);
    }

    // Attach click handlers
    triggersInSet.map(trigger => trigger.addEventListener('click', event => {
      const tabId = trigger.getAttribute('data-tab-trigger');
      const set = document.querySelector('[data-tabs=' + setId + ']');
      const tabInSet = getTabElem(tabId, set);
      const triggerInSet = getTriggerElem(tabId, set);

      if (setId === "fulfilment" || setId == "payment") {
        deactivateAllTabs(setId);
        activate(tabId, setId);
        return;
      }

      if (tabInSet.classList.contains('is-active')) {
        tabInSet.classList.remove('is-active');
        triggerInSet.classList.remove('is-active');
      } else {
        sets[setId].active = tabInSet;
        tabInSet.classList.add('is-active');
        triggerInSet.classList.add('is-active');
      }
    }));

    // Adds 'is-last' class to last tab in set
    setClassForLastTab(setId);
  }

  function activate(tabId, setId) {
    const set = document.querySelector('[data-tabs=' + setId + ']');
    const tabInSet = getTabElem(tabId, set);
    const triggerInSet = getTriggerElem(tabId, set);

    sets[setId].active = tabInSet;
    tabInSet.classList.remove('u-hide');
    triggerInSet.classList.toggle('is-active');
  }

  function deactivateAllTabs(setId) {
    sets[setId].tabs.map(tab => tab.classList.add('u-hide'));
    sets[setId].triggers.map(trigger => trigger.classList.remove('is-active'));
  }

  function getId(tab) {
    return tab.getAttribute('data-tab');
  }

  function getTabElem(id, set) {
    return set.querySelector('[data-tab=' + id + ']');
  }

  function getTriggerElem(id, set) {
    return set.querySelector('[data-tab-trigger=' + id + ']');
  }

  function getActiveTabElem(tabsInSet) {
    return tabsInSet.filter(tab => !tab.classList.contains('u-hide'))[0];
  }

  function setClassForLastTab(setId) {
    const tabsInSet = sets[setId].tabs;
    const lastTabInSet = tabsInSet[tabsInSet.length - 1];

    lastTabInSet.classList.add('is-last');
  }

  function returnNextTab() {
    return tabs[tabs.indexOf(active) + 1];
  }

  function returnPrevTab() {
    return tabs[tabs.indexOf(active) - 1];
  }

  return {
    activate: (id) => activate(document.querySelector('[data-tab=' + id + ']')),

    next: () => {
      const id = returnNextTab().getAttribute('data-tab');

      deactivateAllTabs();
      activate(id);
    },

    prev: () => {
      const id = returnPrevTab().getAttribute('data-tab');

      deactivateAllTabs();
      activate(id);
    }
  }
}();
