window.StoreConnectTheme = window.StoreConnectTheme || {};

$(function () {
  window.StoreConnectTheme.TabVerticalHandler.showFirstVisibleTabByDefault();

  $("[data-vtab-trigger]").on("click", function (e) {
    var clicked = e.target;
    var tabSectionId = clicked.getAttribute("data-vtab-trigger");
    var tabTriggers = $("[data-vtab-trigger]");

    tabTriggers.removeClass("is-active");
    $(clicked).addClass("is-active");

    $("[data-section]").prev().removeClass("is-active");

    $("[data-section=" + tabSectionId + "]")
      .prev()
      .addClass("is-active");
  });
});


window.StoreConnectTheme.TabVerticalHandler = {
  showFirstVisibleTabByDefault: function(self = this) {
    $('[data-vtab-trigger]').removeClass('is-active');
    var firstVerticalTabSelector = $('[data-vtab-trigger]:visible').first();
    firstVerticalTabSelector.addClass('is-active');

    if (firstVerticalTabSelector.length > 0) {
      var tabSectionId = firstVerticalTabSelector[0].getAttribute("data-vtab-trigger");
      $("[data-section=" + tabSectionId + "]")
        .prev()
        .addClass("is-active");
    }
    
    $('[data-vtab-trigger]').last().addClass('is-last');
    $('[data-vtab-panel]').last().addClass('is-last');
  }
}
