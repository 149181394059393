window.StoreConnect = window.StoreConnect || {};

document.addEventListener("DOMContentLoaded", () => {
  const tabTriggers = document.querySelectorAll("[data-tab-trigger]");
  [...tabTriggers].map((tab) => {
    tab.addEventListener("click", event => {
      const providerId = event.target.getAttribute("data-tab-trigger");

      if (providerId) {
        showPaymentForm(providerId);
      }
    });
  });

  const lastProviderElement = document.querySelector("[data-last-used-provider]");
  if (lastProviderElement) {
    const lastProviderId = lastProviderElement.getAttribute("data-last-used-provider");
    if (lastProviderId) {
      showPaymentForm(lastProviderId);
    }
  }
});

function showPaymentForm(providerId) {
  document
    .querySelectorAll("[data-tab]")
    .forEach((tab) => tab.classList.add("sc-hide"));
  document
    .querySelectorAll("[data-tab-trigger]")
    .forEach((tab) => tab.classList.remove("is-active"));

  const tab = document.querySelector(`[data-tab='${providerId}']`);
  const trigger = document.querySelector(`[data-tab-trigger='${providerId}']`);

  tab.classList.remove("sc-hide");
  trigger.classList.add("is-active");

  if (window.StoreConnect.showPaymentProviderCallbacks[providerId]) {
    window.StoreConnect.showPaymentProviderCallbacks[providerId].show();
  }
}

window.StoreConnect.showPaymentProviderCallbacks = {};
