window.StoreConnectTheme = window.StoreConnectTheme || {};

$(function () {
  window.StoreConnectTheme.OptionSelectShowHandler.init();
});

window.StoreConnectTheme.OptionSelectShowHandler = {
  init: function (self = this) {
    self.hideAll();
    $("[data-option-select-trigger]").each(function (index, target) {
      self.showTarget(target.value);
    });

    $("[data-option-select-trigger]").on("change", function (e) {
      var clicked = e.target;
      self.hideAll();
      self.showTarget(clicked.value);
    });
  },
  hideAll: function () {
    $("[data-option-select-target]").hide();
  },
  showTarget: function (target) {
    if (target != "") {
      $("[data-option-select-target='" + target + "']").show();
    }
  }
}
