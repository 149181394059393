import {initQtyPickers} from './quantity-picker';
import Rails from "@rails/ujs";

document.addEventListener('DOMContentLoaded', () => {
    const quantityPickers = document.querySelectorAll("form[data-submit-on-change=true] [data-qty-picker] input");

    if (quantityPickers.length > 0) {
        document.body.addEventListener("ajax:success", initQtyPickers);
    }
});
