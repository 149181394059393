(function () {
  document.addEventListener('DOMContentLoaded', () => {
    const elems = document.querySelectorAll('[data-datetime]');

    [...elems].map(elem => localize(elem));
  });

  function localize(elem) {
    dateTimeStr = elem.getAttribute('data-datetime')
    overrideFormat = elem.getAttribute('data-datetime-format')

    dateFormat = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }
    timeFormat = {
      hour: 'numeric',
      minute: 'numeric',
    }

    switch (overrideFormat) {
      case "date":
        format = {
          ...dateFormat
        }
        break;
      case "time":
        format = {
          ...timeFormat
        }
        break;
      default:
        format = {
          ...dateFormat,
          ...timeFormat
        }
    }

    formatter = new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, format);
    outputString = formatter.format(Date.parse(dateTimeStr));

    // Mainly used for sharp. Remove if no longer needed
    if(overrideFormat == "special") {
      outputString = outputString.replaceAll("/","-");
    }

    elem.innerHTML = outputString

    elem.setAttribute('title', Intl.DateTimeFormat().resolvedOptions().timeZone);
  }
})();
