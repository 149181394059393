// overridden

(function() {  
  function init() {
    var target = document.querySelectorAll('[data-search-target]');

    $(target).hide()
  }

  function onClick(event) {
    var triggerAttribute = 'data-search-trigger';
    var targetAttribute = 'data-search-target';
    var target = document.querySelector('[' + targetAttribute + ']');
    var input = document.querySelector("[data-search-input]");
    var clicked = event.target;

    if (clicked.hasAttribute(triggerAttribute)) {
      event.preventDefault();
      $(target).slideToggle();
      $(input).focus();
    } else {
      if (!clicked.hasAttribute(targetAttribute) && !clicked.hasAttribute("data-search-input")) {
        $(target).slideUp();
      }
    }
  }

  document.addEventListener('DOMContentLoaded', init, false);
  document.addEventListener('click', onClick, false);
}());
